.ss-cancel-invoice-notes-modal-container {
    width: 60vw;
    height: 40vh;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    overflow: hidden;
    top: -20vh;
}

.ss-cancel-invoice-modal-div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.ss-cancel-invoice-modal-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ss-cancel-invoice-modal-title {
    font-size: 20px;
    font-weight: bold;
}

.ss-cancel-invoice-modal-notes-input {
    height: 80px;
    width: 100%;
    max-width: 500px;
    font-size: 15px;
    line-height: 20px;
    padding: 5px;
}

.ss-cancel-invoice-modal-notes-input::placeholder {
    font-size: 15px;
}

.ss-cancel-invoice-notes-title-container { 
    padding-bottom: 10px;
    border-bottom: 1px lightgray solid;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ss-cancel-invoice-required-field {
    color: tomato
}

.ss-cancel-invoice-notes-input-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ss-cancel-invoice-notes-description {
    text-align: center;
}