.ss-sign-up-info-container {
    display: flex;
    flex-direction: column;
}

.ss-signup-container {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.068) 0%, rgba(63, 81, 181, 0) 100%), url(/app-images/login/login_background.png);
    height: 28.57em;
    background-position: center !important;
    background-size: cover;
}

.MuiSelect-select:focus {
    background-color: transparent !important;
}

.ss-sign-up-info-map-parent {
    padding: 3rem 2.5rem 0 2.5rem;
    margin-bottom: 1.5rem;
    border-radius: 0.57rem !important;
}

.ss-sign-up-info-map-container {
    display: flex;
    flex-direction: column;
}

.ss-sign-up-info-map-title {
    margin-bottom: 2rem !important;
}

.ss-sign-up-info-map-item-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 3rem;
}

.ss-sign-up-info-icon {
    width: 2.71em;
    height: 2.71em;
    object-fit: cover;
}

.ss-sign-up-info-map-item-icon {
    padding-right: 6%;
    color: rgba(0, 0, 0, 0.61);
}

.ss-sign-up-info-map-item-text-container {
    width: 80%;
}


.ss-switch-account-type-button {
    background-color: #3F51B5 !important;
    padding: 1.57rem 2.97rem !important;
    width: 100%;
    border-radius: 0.57rem !important;
}

.ss-switch-account-type-button-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 0.57rem;
    text-transform: none;
}