.ss-location-profile-pricing {
    margin-bottom: 2em;
}

.ss-location-profile-pricing-grid-titles {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ss-location-profile-pricing-subcontainer-icon-title {
    display: flex;
    justify-content: space-between;
    max-width: 28%;
}

.ss-location-profile-pricing-subcontainer-titles {
    display: flex;
    width: 25%;
    justify-content: space-between;
}

.ss-location-profile-pricing-grid-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e06e;
    height: 5em;
}

.ss-location-profile-pricing-subcontainer-item {
    width: 40%;
    margin-left: 2em;
}

.ss-location-profile-pricing-subcontainer-items-first {
    display: flex;
    justify-content: flex-end;
}

.ss-location-profile-pricing-subcontainer-items-second {
    display: flex;
    justify-content: flex-start;
}

.ss-location-profile-pricing-subcontainer-items {
    display: flex;
    width: 35%;
    justify-content: space-evenly;
}

.ss-location-profile-pricing-type-icon-container {
    display: flex;
    align-items: center;
}

.ss-location-profile-pricing-icon {
    color: rgba(12, 12, 12, 0.38);
    margin-right: .5em;
}


.ss-location-profile-pricing-current-average-rate-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2em;
}

.ss-location-profile-pricing-current-average-title-container {
    display: flex;
}

.ss-location-profile-pricing-current-average-ppd {
    display: flex;
    justify-content: space-between;
}

.ss-location-profile-pricing-current-average-rate-container {
    width: 10%;
    display: flex;
    justify-content: center;
}

.ss-price-per-container {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    padding-right: 1em;
}

.ss-location-profile-pricing-title {
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 1em !important;
}

.ss-location-profile-pricing-subtitle {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 1em !important;
}

.ss-price-per-subtitle {
    margin: 4em
}

.ss-price-per-day {
    padding-right: 1em;
}

.ss-price-per-month {
    padding-left: 1em;
}

@media(max-width:39.28em) {

    .ss-location-profile-pricing-type-icon-container {
        width: 58%;
    }

    .ss-ocation-profile-pricing-title {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ss-location-profile-pricing-subcontainer-item{
        margin-right: 2em;
    }
    #appNav + div{
        padding: 0px;
    }
    .ss-location-profile-pricing{
        padding: 0.3em;
    }
}