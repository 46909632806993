
@media (max-width:550px){
    .search_input{
        width: 330px !important;
        margin: auto !important;
    }

   .search_input .MuiGrid-grid-xs-6 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}