.popular-location-listing-container {
    padding: 3.5% 7.6%;
}

.popular-location-listing-container-grid {
    row-gap: 2.36em;
    margin-top: 30px;
}

.sign-up-link {
    color: rgba(63, 81, 181, 1);
    text-decoration: underline !important;
}

.location-panel-container {
    row-gap: 2.3rem;
    column-gap: 3.2rem;
}

.ss-popular-location-list {
    background-color: '#FBFBFF';
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;

}

.location-panel-container .city-img {
    width: 3.14em;
    height: 3.14em;
    border-radius: 0.71rem;
}

.location-panel {
    max-width: none;
    gap: .93em;
    align-items: center;
}

@media all and (min-width: 495px) {
    .location-panel {
        max-width: 12.8em;
    }
}

.location-panel .location-details-container {
    padding-top: .07em;
}

.location-details-container .location-name {
    word-break: normal;
    overflow-wrap: anywhere;
}

.popular-location-listing-container .header1 {
    font-family: 'Satoshi-Variable', 'Roboto', 'sans serif';
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 120%;
}

.header1{
    font-size: 20px !important;
}
.header2{
    font-size: 14px !important;
}

.popular-location-listing-container .header2 {
    font-family: 'Inter', 'Roboto', 'sans serif';
    font-size: 1rem;
    font-weight: 400;
}
