.ss-location-features-option-parent {
    width: 100%;
    margin-bottom: 1em;
    row-gap: 1.36rem;
    
}

.ss-location-features-option-title {
    width: 100%;
}

.ss-location-features-option-item-parent {
    gap: 1.36rem 1rem;
}

.ss-location-features-option-item {
    width: 30%; /* To ensure items appear on the same row */
    display: flex;
    align-items: center;
}

@media (max-width: 39.28em) {
    .ss-location-features-option-item {
        width: 45%;
    }
}

.ss-location-features-item-name {
    margin-left: 20px !important;
}