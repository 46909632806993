.component-barcode-scanner {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    min-width: 150px;
    min-height: 60px;
}

.dbrScanner-bg-loading {
    animation: 1s linear infinite dbrScanner-rotate;
    width: 40%;
    height: 40%;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    fill: #aaa;
}

.dbrScanner-bg-camera {
    width: 40%;
    height: 40%;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    fill: #aaa;
    display: none;
}

.dbrScanner-video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    object-fit: contain;
    z-index: 2;
    top: 60px;
}

.dbrScanner-cvs-drawarea {
    width: 30%;
    height: 30%;
    position: absolute;
    left: 0;
    object-fit: contain;
    z-index: 2;
    top: 60px;
}

.dbrScanner-cvs-scanarea {
    width: 75%;
    height: 75%;
    position: absolute;
    left: 0;
    z-index: 2;
    top: 60px;
}

.dbrScanner-scanlight {
    width: 100%;
    height: 3%;
    position: absolute;
    animation: 3s infinite dbrScanner-scanlight;
    border-radius: 50%;
    box-shadow: 0px 0px 2vw 1px #00e5ff;
    background: #fff;
    top: 60px;
    display: none;
}

.dbrScanner-sel-camera {
    margin: 0 auto;
    position: absolute;
    left: 0;
    top: 0;
}

.dbrScanner-sel-resolution {
    position: absolute;
    left: 0;
    top: 20px;
}

@keyframes dbrScanner-rotate {
    from {
        transform: rotate(0turn);
    }
    to {
        transform: rotate(1turn);
    }
}

@keyframes dbrScanner-scanlight {
    from {
        top: 10%;
    }
    to {
        top: 97%;
    }
}