.ss-mapBox {
    flex: 1;
}

.ss-location-list-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: inherit;
}

.ss-search-result-container {
    background-color: #FBFBFF;
    width: 45.14rem;

}

.ss-small-screen-controller {

    position: absolute;
    bottom: 5%;
    left: 50%;
    display: none;
    z-index: 100;
}

@media screen and (max-width: 1080px) {
    .ss-mapBox {
        display: none !important;
    }

    .ss-show-map {
        display: flex !important;
        flex: 1;
    }

    .ss-hide-list {
        display: none !important;
    }

    .ss-location-list-container {
        flex: 1;

    }

    .ss-search-result-container {
        width: 100%;
    }

    .ss-small-screen-controller {
        display: flex;
        height: 2.5em;
        width: 10em;
        background-color: red;
        border-radius: .8em;
        background-color: whitesmoke;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
        transform: translateX(-50%);
        align-items: center;
        justify-content: space-around;
        font-size: 1.2em;
    }

    .ss-ctl-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .4em;
    }

    .ss-ctl-btn-active {
        color: #f79019
    }
}