#ssLoginFormContainer {
    width: 100%;
    padding: 30px;
}

#ssLoginFormContainer > h2 {
    padding-bottom: 20px;
}

@media all and (min-width: 1000px) {
    .ss-login-as-container {

        display: flex;
        justify-content: center;
        width: 40%;

    }
}

@media all and (max-width: 999px) {
    .ss-login-as-container {
        padding: 5px;
        justify-content: center;
    }
}