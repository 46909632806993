.top-tier{
    width: 100%;
    margin-top: 6em;
}

.ss-top-tier-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 6em;
}

.ss-top-tier-item {
    float: none !important;
    list-style: none;
    position: relative;
}

.top-tier h2 {
    color: #324559;	font-size: 18px;	font-weight: 700;	text-align: center;
    padding:0;
    margin-bottom: 50px;
}

.top-tier li {
    text-align: left;
    padding: 0;
    float: left;
}

.top-tier li img {
  margin:auto;
  max-height: 38px;
}

.top-tier .bx-wrapper {
    box-shadow: none;
    border: none;
    background: transparent;
    margin-bottom: 42px !important;
}


.top-tier  .bx-wrapper .bx-prev {
    left: 0;
    background-image: url("/app-images/slider/left_arrow.png");
    background-position: 0 0;
    margin-top:-15px;
}

.top-tier  .bx-wrapper .bx-next {
    right: 0;
    background-image: url("/app-images/slider/right_arrow.png");
    background-position: 0 0;
    margin-top:-15px;
}


@media all  and (max-width: 550px) {
    .top-tier{
        padding: 50px 5%;
    }
    .top-tier h2{
        padding: 0;
        font-size:16px;
    }
    .top-tier li img{
        margin-left:0;
    }

    .top-tier .bx-wrapper{
        padding:0 20px;
    }
    .top-tier  .bx-wrapper .bx-next {
        right:-8px;
    }
}