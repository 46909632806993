.ss-error {
    margin-bottom: 8px;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 1;
    color: #d74747;
    white-space: pre-wrap;
}
