.ss-blog-input input::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
}
.ss-external-landing{
    height: 100vh;
}

.ss-blog-input{
    margin-bottom: 2em !important;
}

.ss-blog-sub-input label{
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 0.9em;
}
.ss-blog-sub-input input::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1em;
    color: #FFFFFF;
}

.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 1px solid white !important;
}
.ss-blog-sub-input input{
    color: #fff;
}

.ss-blog-sub-input .MuiInput-underline:before{
    border-bottom: 1px solid rgb(253 249 249 / 42%);
}
.ss-blog-sub-input .MuiInput-underline:after{
    border-bottom: 1px solid rgb(253 249 249 / 42%);
}

.ss-blog-sub-input .MuiFormLabel-root.Mui-focused{
    color: #FFFFFF;
}
.ss-blog-container{
    max-width: 95em;
    margin: 0 auto;
    padding: 1em;
}

