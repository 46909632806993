.ss-report-data-base {
    padding: 6px 0 6px 32px;
    min-width: 300px;
}

.ss-report-data-children {
    overflow: hidden;
    color: #324559;
    font-size: 12px;
    font-weight: bold;
    white-space: pre-wrap;
}

.ss-report-data-children.modified {
    background-color: #f79019;
}

.ss-report-data-label {
    color: #5D6B7A;
    font-size: 15px;
    font-weight: 300;
    text-transform: uppercase;
}

.ss-report-data-date {
    padding: 6px 0 6px 32px;
    min-width: 300px;
    background: url('/app-images/admin/group@2x.png') no-repeat;
    background-position: 3px center;
    background-size: 26px 26px;
    border-radius: 0 !important;
}

.ss-report-data-location {
    padding: 6px 0 6px 32px;
    min-width: 300px;
    background: url('/app-images/admin/list@2x.png') no-repeat;
    background-position: 3px center;
    background-size: 26px 26px;
    border-radius: 0 !important;
}

.ss-report-data-amount {
    padding: 6px 0 6px 32px;
    min-width: 300px;
    background: url('/app-images/admin/amount@2x.png') no-repeat;
    background-position: 3px center;
    background-size: 26px 26px;
    border-radius: 0 !important;
}

.status.report-value[title*="Approved"],
.status.report-value[title*="Manual-Payment"]{
    color:#42C655;
}

.status.report-value[title*="Decline"],
.status.report-value[title*="Cancelled"],
.status.report-value[title*="Failed"]{
    color:red;
}

.status.report-value[title*="Pending"],
.status.report-value[title*="Incomplete"]{
    color:#F09019;
}
