.search-equipment-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #777;
    padding: 2rem;
    min-width: 20rem;
    max-width: 40rem;
    max-height: 700px;
    opacity: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 10000;
}

#searchSearchFields {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;

    padding: 0 20px 20px 20px;
    flex-wrap: wrap;
}

.search-search-item {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 28px;
}

#searchSearchLocationName {
    width: 200px;
}

#numberOfSpaces {
    width: 100%;
}

#searchLocationFeaturesItems, #searchEquipmentTypeItem {
    min-width: 200px;
}

.live-only-button-container {
    margin: 0 20px;
}

.live-only-button {
    background-color: #fff;
    color: #d74747;
    width: 150px;
    padding: 8px;
    border-radius: 17px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .3px;
    border: 1px solid #d74747;
    cursor: pointer;
}

.live-only-button:hover {
    background-color: #d74747;
    color: #fff;
    border-color: #d74747;
}

@media all and (min-width: 600px) and (min-height: 975px) {
    .search-equipment-modal {
        border-radius: 5px;
    }
}

.search-equipment-modal-in {
    transition: opacity 0.5s ease-in;
    opacity: 1
}

.search-equipment-modal-out {
    transition: opacity 0.3s ease-out;
    opacity: 0
}

.search-equipment-modal-backdrop {
    background-color: black;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.search-equipment-modal-backdrop-in {
    transition: opacity 0.3s ease-in;
    opacity: 0.5;
}

.search-equipment-modal-backdrop-out {
    transition: opacity 1.5s ease-out;
    opacity: 0;
}

.search-location-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #777;
    padding: 2rem;
    min-width: 20rem;
    max-width: 40rem;
    max-height: 600px;
    opacity: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 10000;
}

@media all and (min-width: 600px) and (min-height: 975px) {
    .search-location-modal {
        border-radius: 5px;
    }
}

.search-location-modal-in {
    transition: opacity 0.5s ease-in;
    opacity: 1
}

.search-location-modal-out {
    transition: opacity 0.3s ease-out;
    opacity: 0
}

.search-location-modal-backdrop {
    background-color: black;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.search-location-modal-backdrop-in {
    transition: opacity 0.3s ease-in;
    opacity: 0.5;
}

.search-location-modal-backdrop-out {
    transition: opacity 1.5s ease-out;
    opacity: 0;
}

#searchMap.w100 {
    width: calc(100% - 530px);

    border-radius: 5px;
    border: 1px solid #E7E7E7;
    background: #F6F4F4;
}

.ss-supplier-list-container, #supplierDetails ,.need-help > div{
    width: 600px;
}

.ss-supplier-list-container {
    border-radius: 5px 0 0 5px;
    border: 1px solid #E7E7E7;
    padding: 14px 7px 34px 7px;
    background: #F6F4F4;
    overflow: auto;
}

#supplierDetails {
    border-radius: 5px 0 0 5px;
    border: 1px solid #E7E7E7;
    background: #F6F4F4;
    position: relative;
    -webkit-overflow-scrolling: touch;
}

#searchMap #map-element {
    border-radius: 0 5px 5px 0;
    border: 1px solid #E7E7E7;
}

#ssSearch {
    width: 100%;
    position: relative;

}

#ssSearch .ss-show {
    display: block !important;
}

#ssSearch .ss-hidden {
    display: none !important;
}

#ss-search-result-message-container {
    pointer-events: auto;
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

/**Style for legend map*/
#map-legend {
    font-family: SofiaPro, sans-serif;
    padding: 10px 10px 10px 5px;
    margin: 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
    font-size: 12px;
}

#map-legend h3 {
    margin-top: 0;
}

#map-legend img {
    vertical-align: middle;
    height: 20px;
    margin: 5px;
}

@media all and (min-width: 1000px) {

    #searchCriteria {
        width: 360px;
        position: relative;
        top: 15px;
        left: 20px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        background-color: #FFFFFF;
        z-index: 3;
    }

    .search-result-message {
        display: block;
        margin: 0 auto 0 auto;
        text-align: center;
        position: relative;
    }

    .no-suppliers-message {
        display: block;
        margin: 0 auto 0 auto;
        padding: 10px;
        text-align: center;
        position: relative;
    }

    .search-calendar-icon {
        width: 20px;
        height: 20px;
        margin-top: 15px;
        margin-left: 15px;
    }

    .search-map-icon {
        width: 16px;
        height: 21px;
        margin-top: 15px;
        margin-left: 15px;
    }

    .search-parking-icon {
        width: 21px;
        height: 21px;
        margin-top: 15px;
        margin-left: 15px;
    }

    .search-number-selected {
        width: 120px;
        cursor: pointer;
    }

    .search-number-selected:disabled {
        background-color: #FFFFFF;
        cursor: pointer;
    }

    #searchSearchStartDate {
        width: 110px;
    }

    #searchSearchEndDate {
        width: 110px;
    }

}

.admin-booking-op-row {
    min-width: 250px;
}

@media all and (max-width: 1200px) {
    #searchMap {
        height: auto;
        flex: 1;
    }

    .ss-supplier-list-container, #supplierDetails,.need-help > div {
        width: 300px;
        min-width: 50%;
    }

    .non-admin-booking-op-row {
        min-width: 250px;
    }

    .search-search-panel-button-container {
        margin-left: 0;
        margin-right: 0;
    }

    #refineYourSearch {
        position: absolute;
        top: 10px;
        left: 7px;
        right: 7px;
        height: 56px;
        padding-left: 20px;
        vertical-align: middle;
        line-height: 56px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        background-color: #ffffff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        z-index: 3;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        text-align: left;
        color: #999999;
    }

    .ss-location-header {
        position: relative;
        font-size: 14px !important;
        font-weight: 500 !important;
        text-align: left !important;
        color: #555555 !important;
    }

    #refineYourSearch:after {
        content: url(https://static.secur.space/app-images/icon-search.svg);
        z-index: 3;
        position: absolute;
        right: 24px;
        top: 3px;
    }

    .search-calendar-icon {
        width: 20px;
        height: 20px;
        margin-top: 15px;
        margin-left: 15px;
    }

    .search-map-icon {
        width: 16px;
        height: 21px;
        margin-top: 15px;
        margin-left: 15px;
    }

    .search-parking-icon {
        width: 21px;
        height: 21px;
        margin-top: 15px;
        margin-left: 15px;
    }

    .search-number-selected {
        width: 120px;
        cursor: pointer;
    }

    .search-number-selected:disabled {
        background-color: #FFFFFF;
        cursor: pointer;
    }

    #searchSearchStartDate {
        width: 101px;
    }

    #searchSearchEndDate {
        width: 90px;
    }

}

@media all and (min-width: 1000px) and (min-height: 790px) {
    #searchMap {
        height: auto;
        flex: 1;
    }
}

@media all and (max-width: 600px) {
    .ss-supplier-list-container, #supplierDetails, .need-help > div {
        width: 100%;
    }

    #searchMap.w100 {
        display: none;
    }

    #searchMap.w100.visible-forced {
        min-height: 100vh !important;
        display: block;

    }

    #searchLocationItem {
        width: 100%;
    }

    #searchEndDateItem, #searchStartDateItem {
        width: 110px;
    }

    #searchEquipmentTypeItem {
        width: 100%;
    }

    #searchLocationFeaturesItems {
        width: 100%;
    }

    .search-search-panel-button-container {
        width: 100%;
        text-align: center;
    }

    .search-button {
        width: 200px;
    }
    .live-only-button-container {
        text-align: center;
        margin-top: 10px;
        width: 100%;
    }

}
@media all and (max-width: 530px) {
    #searchMap.w100.visible-forced{
        border:none;
        display: none;
    }
}
