.ss-change-password-section-container {
    height: 26.42em !important;
}

.ss-change-password-container {
    box-shadow: 0em 0.21em 0.07em -0.15em rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0em 0.15em 0.15em rgba(0, 0, 0, 0.14)) drop-shadow(0px 0.07em 0.35em rgba(0, 0, 0, 0.12));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.068) 0%, rgba(63, 81, 181, 0) 100%), url(/app-images/login/login_background.png);
    height: 37.14em;
    background-position: center !important;
    background-size: cover;
}

.ss-change-password-content {
    background: #FFFFFF;
    box-shadow: 0px .57em .58em -.4em rgba(0, 0, 0, 0.2), 0px 1.1em 1.7em 2px rgba(0, 0, 0, 0.14), 0px .4em 2em .4em rgba(0, 0, 0, 0.12);
    border-radius: 0.6em;
    width: 32.57em;
    padding: 3.78em 3.78em 1em 3.78em;
    transform: translateY(-40%);
}

.ss-change-password-title {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1.71em !important;
    line-height: 133.4%;
    color: #000000;
    margin-bottom: 0.4em !important;
}

.ss-change-password-subtitle {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1.1em !important;
    line-height: 150%;
    letter-spacing: 0.15px !important;
    color: #000000;
}

.ss-change-password-form {
    margin-top: 3em;
}

.ss-change-password-form input::placeholder {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1em !important;
    line-height: 1.9em;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.38);
}

.ss-change-password.ss-change-password-confirm {
    margin-top: 2em;
}

.ss-change-password-form .MuiFormHelperText-root {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 0.9em !important;
    line-height: 1.42em !important;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.54);
}
.ss-change-password-validation-title{
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1em !important; 
    line-height: 143%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87)
}
.ss-change-password-validation-title.disabled{
    color: rgba(0, 0, 0, 0.459);;
}

.ss-change-password-validation-icon{
    width: 1.1em;
    height: 1.1em;
    object-fit: cover;
}

.ss-change-password-validation{
    margin-top: 1.2em !important;
    height: 13em !important;
}

.ss-change-password-icon-password{
    width: 1.43em;
    height: 1.14em;
    object-fit: contain;
}

.ss-change-flex-container{
    gap: 1em !important;
    margin-bottom: 0.6em !important;
}

.ss-change-password-footer {
    margin-top: 5.14em;
    gap: 0.21em;
}

.ss-change-password-footer .ss-change-password-account-title, .ss-change-password-login{
    font-family: 'Inter' !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
}

.ss-change-password-footer .ss-change-password-login{
    color: #3F51B5 !important
}

@media (max-width:550px){
    .ss-change-password-content {
        width: 100%;
        transform: translateY(-30%);
    }
    .ss-change-password-section-container {
        padding: 1.5em;
    }
}