.ss-row-action {
    position: relative;
    text-align: right;
}

.ss-action-item {
    width: 100%;
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #555555;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    text-align: left;
}

.ss-action-item:hover {
    font-weight: 700;
}

.ss-action-options-hidden {
    visibility: hidden;
    position: absolute;
    max-height: 300px;
    overflow: hidden;
}

.ss-action-item img {
    width: 16px;
    margin-left: -20px;
    margin-right: 4px;
    margin-top: -2px;
}

.ss-action-options-visible {
    visibility: visible;
    position: absolute;
    width: 200px;
    top: 18px;
    right:0;
    z-index: 9999;
    background-color: #f2f2f2;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #dddddd;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.ss-action-list {
    list-style: none;
    padding: 0;
    width: 100%;
}

.ss-row-action-icon {
    height: 25px;
    width: 25px;
    cursor: pointer;
}