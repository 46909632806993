.ss-login-section-container{
    height: 26.42em !important;
}
.ss-login-container {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.068) 0%, rgba(63, 81, 181, 0) 100%), url(/app-images/login/login_background.png);
    height: 37.14em;
    background-position: center !important;
    background-size: cover;
}

.ss-login-content-container {
    box-shadow: 0px .57em .58em -.4em rgba(0, 0, 0, 0.2), 0px 1.1em 1.7em 2px rgba(0, 0, 0, 0.14), 0px .4em 2em .4em rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 32.57em;
    transform: translateY(-50%);
}

.ss-login-form {
    margin-top: 2em;
}

.ss-login-icon-email{
    width: 1.43em;
    height: 1.14em;
    object-fit: cover;
}
.ss-login-icon-password{
    width: 1.57em;
    height: .9em;
    object-fit: cover;
}

.ss-login-form input::placeholder{
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1em !important;
    line-height: 1.9em;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.38);
}

.ss-login-input label{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.9em !important;
    line-height: 0.9em;
    letter-spacing: 0.15px;
}

.ss-login-input.password {
    margin-top: 2em;
}
.ss-login-input.email label{
    color: #6639BF !important;
}

.ss-login-input.password label{
    color: rgba(0, 0, 0, 0.38);
}
.ss-login-forgot-section{
    margin-top: 2.8em;
    margin-bottom: 5.57em;
}
.ss-login-remember-title span{
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1em !important; 
    line-height: 143%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87)
}

.login-message-container {
    position: relative;
    top: 1.1em;
    left: 1.1em;
    float: left;
    text-align: center;
    margin-bottom: 2em;
}

@media (max-width:550px){
    .ss-login-content-container {
        width: 100%;
    }
    .ss-login-section-container{
        padding: 1.5em;
    }
}