#ssNavMenu {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ss-nav-menu-item {
    display: inline;
    padding-right: 30px;
}

.ss-nav-menu-item:last-child {
    padding-right: 0;
}

.login_btn {
    border: 3px solid #fff;
    padding: 10px 25px;
    color: #fff;
    cursor: pointer;
}

.ss-nav-drawer {
    display: none !important;
}

.ss-nav-drop-down-menu span {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 1em;
    line-height: 220%;
    letter-spacing: 0.1px !important;
    color: rgba(0, 0, 0, 0.87) !important
}

.ss-dropw-down-menu-main {
    margin-top: 1em;
}

.ss-dropw-down-menu-main .MuiMenu-list {
    width: 9em !important;
    background: #FFFFFF !important;
    box-shadow: 0.3em 0.35em 0.35em -0.21em rgba(0, 0, 0, 0.336), 0.3em 8px 10px 1px rgba(0, 0, 0, 0.308), 0.3em 0.21em 1em 0.15em rgba(0, 0, 0, 0.329) !important;
    border-radius: 0.28em !important;
    position: relative;
    z-index: 9999;
}
.ss-dropw-down-inactive{
    background-color: transparent;
}

.ss-drop-down-active{
    background-color: rgba(63, 81, 181, 0.08) !important;
    color: #3F51B5 !important;
}


@media all and (min-width: 1000px) {
    #ssNavMenu {
        position: relative;
        display: flex;
        align-items: center;
    }

    .ss-close-left {
        display: none;
    }

    .ss-nav-menu-item {
        position: relative;
    }
}



@media all and (max-width: 999px) {
    #ssNavMenu {
        margin-top: 70px;
    }

    .ss-nav-drawer {
        display: block !important;
        z-index: 1000000;
    }

    #ssNavMenuContainer {
        display: none;
    }

    .ss-close-left {
        position: fixed;
        top: 25px;
        left: 25px;
        height: 18px;
        width: 18px;
        cursor: pointer;
    }


    #ssNavMenu {
        border-top: solid 1px #dddddd;
    }

    .ss-nav-menu-item {
        display: block;
        padding: 20px 0 0 27px;
    }

    #menuCloser {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 250px;
        z-index: 99999;
        pointer-events: none;
    }

    #menuCloser::after {
        content: "";
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 250px;
        margin: 0;
        pointer-events: auto;
    }
}

.ss-nav-menu-item a,
.ss-nav-menu-item span.pointer, .ss-home-nav-menu-item a,
.ss-home-nav-menu-item span.pointer  {
    text-decoration: none;
    color: #000000DE;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 1.3;
    text-align: right;
    position: relative;
}

.ss-home-nav-menu-item a,
.ss-home-nav-menu-item span.pointer {
    color: #ffff;
}


.ss-nav-menu-item a:hover,
.ss-nav-menu-item span.pointer:hover {
    font-weight: 900;
}

.ss-nav-menu-item a:link,
.ss-nav-menu-item span.pointer:link {
    background-color: transparent !Important;
}

.ss-nav-menu-item a:active,
.ss-nav-menu-item span.pointer:active {
    color: #999999 !Important;
    background-color: transparent !Important;
}

.ss-login-btn {
    border: 3px solid black !important;
    width: 90px !important;
    padding-top: .25em !important;
    padding-bottom: .25em !important;
    margin: .8em !important;
    margin-left: 1.1em !important;

}

.ss-nav-menu-item a.active,
.ss-nav-menu-item button.active,
.ss-nav-menu-item span.pointer.active {
    color: var(--primary-color);
    background-color: transparent ;
}

.ss-nav-menu-item-highlighted {
    display: inline-block;
    border-radius: 21px;
    background-color: #f79019;
    color: #FFFFFF !important;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
    padding: 10px 15px;
    letter-spacing: .3px;
}

.ss-nav-submenu-toggle .caret {
    margin-left: 4px !important;
    margin-bottom: 3px !important;
}

.ss-nav-submenu-toggle {
    display: flex;
    align-items: center;
    font-family: 'Satoshi-Variable';
    font-style: normal;
    font-weight: 700;
    font-size: 1.14em;
    line-height: 150%;
    cursor: pointer;
}

.ss-nav-submenu-toggle.ss-account-menu-home{
    color: #fff;
}

.no-link-hover:hover {
    border-bottom: none !important;
}