.booked-space-calendar-invoice-modal-content-container {
    display: flex;
    flex-flow: column;
}

.booked-space-calendar-invoice-modal-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.booked-space-calendar-invoice-modal-header {
    font-size: 30px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 15px;
}

.booked-space-calendar-invoice-modal-overage-items {
    flex: 1;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
}

.booked-space-calendar-invoice-modal-item-container {
    flex: 1;
    text-align: center;
    padding: 15px;
    min-width: 20%;
}

.booked-space-calendar-invoice-modal-item-link {
    font-weight: bold;
    font-size: 20px;
}

.booked-space-calendar-invoice-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.booked-space-calendar-invoice-modal-exit-btn-container {
    width: 20%;
    padding-bottom: 20px;
}
