.ss-blog-content p {
    margin-bottom: 2em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #425b76;
}

.ss-blog-content p span {
    margin-bottom: 2em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #425b76;
}

.ss-blog-content :is(h1, h2) {
    margin: 1em 0 1em 0em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 133.4%;
    font-size: 2.4em;
    color: #311C95;
}

.ss-blog-content :is(h1 span, h2 span) {
    color: #311C95;
    font-size: inherit;
}

.ss-blog-content :is(h3) {
    margin: 0.5em 0 0.5em 0em;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 133.4%;
    font-size: 2.4em;
    color: #29abe2;
}

.ss-blog-content :is(h3 span) {
    color: #29abe2;
    font-size: inherit;
}

.ss-blog-content img {
    margin-bottom: 1.3em;
    margin-top: 1em;
    width: 100%;
}

.ss-blog-content :is(h4) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 133.4%;
    color: #3495DA;
    font-size: 2em;
    margin: 0.3em 0 0.3em 0em;
}
.ss-blog-content h4 strong, h4 span {
    font-size: inherit;
}

.ss-blog-content ul{
    list-style-type: disc;
    color: #425b76;
    padding: 0em 2em;
}
.ss-blog-content ol{
    list-style-type: decimal;
    color: #425b76;
    padding: 0em 2em;

}

.ss-blog-content blockquote {
    background: rgba(52, 149, 218, 0.15);
    margin: 10px 0 24px;
    padding: 2em 5em;
}