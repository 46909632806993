.tab_btn{
    border: none !important;
    outline: none;
    border-radius: 5px !important;
}

.tab_btn span{
    font-size: 12px !important;
    text-transform: capitalize !important;
}

.ss-customer-text {
    margin-bottom: 5.71rem;
    padding-right: 9.8rem !important;
}

.ss-testimonial-arrow-icon {
    background-color: white;
    border-radius: 50%;
    margin-top: -0.5em;
}


.ss-customer-partner-testimonials-head {
    margin-top: 4%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
