.searchinput input::placeholder{
    font-size: 1.12em !important;
    color: #000000;
}
.ss-place-input{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ss-place-input-wrapper{
    display: flex;
    gap: 0.625em;
    align-items: center;
}