.secur-space-modal {
    margin: 30px auto;
    border-radius: 6px;
    outline: 0;
}

.ss-gallery-modal {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    margin: 5em;
}

@media (max-width:39.28em) {
    .ss-gallery-modal{
        margin: 0em;
    }
}

.secur-space-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(47, 47, 47, 0.858);
    z-index: 1000000;
}

.ss-modal-center {
    display:  flex;
    align-items: center;
    justify-content: center;
}

.ReactModal__Body--open {
    overflow: hidden;
}



@media screen and (max-width:39.28em) {
    .ss-gallery-modal{
        height: 28.57em;
    }
}

@media (max-width:39.28em) {
    .ss-gallery-modal{
        width: 100%;
    }
}