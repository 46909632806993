.alert-message h5 {
    font-weight: 500;
    font-size: 18px;
    width: 100%;
}



.alert-message button.close {
    flex: 1;
    text-align: right;
    cursor: pointer;
    opacity: 1;
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.alert-message {
    position: absolute;
    top: 20%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 450px;
    width: 50%;
    overflow: hidden;
    min-width: 280px;
}

.alert-message.large {
    max-width: 650px;
}

.alert-message .modal-footer {
    padding: 20px 0;
}

.alert-message .modal-content > div {
    margin: 0 20px;
}

.react-router-modal__container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
}

.react-router-modal__wrapper {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.modal-form .modal-header {
    border-bottom: none;
}

.modal-form .modal-footer {
    border-top: none;
}

.up-margin {
    margin-top: -25px;
}

.dialog-block {
    background: #fff;
    z-index: 100004;
    display: block;
    width: 280px;
    max-width: 100%;
}

.dialog-block .close {
    margin-left: auto;
    border: none;
    outline: none;
    background-color: transparent;
}

.dialog-block .modal-footer {
    padding: 15px 0 15px 15px;
}

@media (max-width:39.28em){
    .alert-message{
        top: 15%;
    }
}
@media all and (max-width: 770px) {

    .ss-sub-modal-content {
        min-height: 60vh;
    }

    .ss-sub-modal-body {
        height: 45vh;
    }
}