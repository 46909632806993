article.ss-about {
    margin-top: 15rem;
    position: relative;
    z-index: 1;
}

article.ss-about > section {
    padding: 0 8% 0 8%;
}

@media (max-width: 39em){
    article.ss-about > section {
        padding: 0px
    }
}