.ss-report-form-date {
    width: auto;
    font-family: inherit;
    text-indent: initial;
    margin: 8px 25px 13px 25px;
    padding: 0;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.24;
    text-align: left;
    color: #555555;
    background-color: #FFFFFF !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.ss-report-form-date-fieldset {
    min-width: 0;
    vertical-align: baseline;
    margin: 0;
    padding: 0 10em 0 0;
    border: none;
    display: inline-block;
}