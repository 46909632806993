
.ss-main header h2,.ss-main header h1.page-title {
    font-size: 49px;
    margin: auto;
}

.ss-modal-dialog {
    max-width: 599px;
    margin: 30px auto;
}

.ss-modal-body{
    border-bottom: 1px solid #ddd;
    padding: 1.5em;
}

@media (max-width: 600px) {
    .ss-modal-dialog {
        max-width: 100%;
        margin: 1em;
    }
    .ss-modal-body{
        padding: 1em;
    }
}

footer{
    width: 100%;
    float:left;
    z-index: 99;
    background: #F6F6F6;
}

.ss-main.ss-horizontal > footer {
    width: 100%;
    background-color: #f6f6f6;
}

.modal-content{
    max-height: 80vh;
    overflow: auto;
}

.modal-footer .table{
    margin-bottom:0;
}
.custom-container{
    padding:30px 90px;
    margin-top: 30px;
}

h4.ss-summary{
    margin-bottom:10px;
}
.flex-wrap{
    flex-wrap: wrap;
}

.ss-circle-box{
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}
.ss-circle-box.active{
    border: 2px solid var(--primary-color);
    transition: all 0.3s ease-in-out;
}
.ss-circle-main{
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: var(--primary-color);
}
.ss-circle-container{
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    gap: 0.4em;
}
/*Hide hubspot chat bot*/

.active_bookings #hubspot-messages-iframe-container,
.current_inventory #hubspot-messages-iframe-container,
.interchanges #hubspot-messages-iframe-container,
.react-router-modal__modal-open #hubspot-messages-iframe-container,
.simplified_customer_app  #hubspot-messages-iframe-container
{
    display:none!important;
}

.react-router-modal__modal-open footer{
    display:none;
}

@media all and (min-width: 1000px) {
  

    .ss-toast {
        width: 500px !important;
        font-family: SofiaPro, "Helvetica Neue", Arial, sans-serif !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        -webkit-border-radius: 5px !important;
        -moz-border-radius: 5px !important;
        border-radius: 5px !important;
        text-align: center !important;
    }
}

@media all and (max-width: 999px) {


    .ss-toast {
        font-family: SofiaPro, "Helvetica Neue", Arial, sans-serif !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        -webkit-border-radius: 5px !important;
        -moz-border-radius: 5px !important;
        border-radius: 5px !important;
        text-align: center !important;
    }
}
