.ss-recently-booked-map-container {
    display: flex;
    flex-wrap: nowrap !important;
    width: 100%;
    overflow: hidden;
}

.ss-recent-booked-locations {
    column-gap: 2.2rem;
    flex-wrap: nowrap !important;
}

.ss-recently-booked-container {
    padding: 1.5em 5.5rem 1.5em 5.5em !important;
    position: relative;
}
.ss-swiper-container-padding{
    padding-left: 4em !important;
    padding-right: 4em !important;
}

.ss-swiper-container-padding .swiper-horizontal{
    padding-bottom: 1em;
}

.ss-mouse-point {
    cursor: pointer;
}


.ss-recently-booked-arrow-left {
    left: 0;
}

.ss-recently-booked-arrow-right {
    right: 0;

}

.ss-location-quick-view-item-container {
    box-shadow: 0 0.5em 1em 0.5em rgba(63, 81, 181, 0.05);
    transition: all 0.3s ease-in;
    background-color: #FFFFFF;
    height: 21.92em !important;
    width: 20.71rem !important;
    border-radius: 1em;
    overflow: hidden;
}

.ss-location-quick-view-item-container:hover {
    transform: scale(1.03);
}

.ss-location-quick-view-item-price {
    font-size: 1.28em !important;
    letter-spacing: 0.015em !important;
    font-weight: 600 !important;
    font-family: Inter;
}

.ss-location-quick-view-item-price-units {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}

.ss-location-quick-view-item-title {
    color: #3F51B5;
    font-weight: 600 !important;
    line-height: 160% !important;
    font-size: 1.28em !important;
    font-family: Inter;
}

.ss-location-quick-view-item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.42em 1.78em !important;
}

.swiper, swiper-container{
    margin: 0px !important;
}

.ss-location-quick-view-item-info>* {
    margin-top: 0.29em !important;
}

.ss-location-quick-view-item-city {
    font-weight: 400;
    color: #2E363E !important;
    font-size: 1em !important;
    margin-top: 0.285em !important;

}

.ss-location-quick-view-item-title {
    text-decoration: none !important;
    font-size: 1.28em;
    width: 100%;
}

.ss-recently-booked-arrow-right {
    z-index: 10000;
}

.ss-recently-booked-section {
    background-color: #FBFBFF;
}

.ss-location-quick-view-item-approval-box {
    margin-bottom: .4em;
    padding: .3em .6em;
    border-radius: 0.3em;
    height: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ss-location-quick-view-item-approval-box.instant-approval {
    background-color: rgba(0, 197, 102, 1);
}

.ss-location-quick-view-item-approval-box.request-space {
    background-color: tomato;
}

.ss-location-quick-view-item-approval {
    color: white;
    font-size: 0.71em !important;
    font-weight: 500 !important;
    text-align: center;
    font-size: 0.71em !important;
}

@media (max-width:550px){
    .ss-swiper-container-padding{
        padding: 0px !important;
    }
    .ss-location-quick-view-item-container{
        width: 100%;
    }
}