.manage-user-item-info-container {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    align-items: center;
}

.ss-manage-user-item-parent {
    border: 1px solid #e1e1e1;
    border-bottom: 0px solid white;
}

.ss-manage-user-item-parent:first-child {
    border-radius: 3px 3px 0px 0px;
}

.ss-manage-user-item-parent:last-child {
    border: 1px solid #e1e1e1;
    border-radius: 0px 0px 3px 3px;
}

.ss-manage-user-info-sub-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 10px;
    justify-content: space-around;
}

.manage-user-item-container:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
}

.manage-user-info-item {
    /* flex: 1; */
    color: #324559;
    font-size: 13px;
    font-weight: 700;
    padding-left: 3px;
    padding-right: 3px;
    word-break: break-word;
}

.ss-existing-username {
    width: 35%;
}

.ss-existing-name {
    width: 25%;
}

.ss-existing-role {
    width: 20%;
}

.ss-existing-phone-number {
    width: 20%;
}

.manage-user-info-action-item-container {
    flex: 1;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.manage-user-info-action-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-locations-button {
    margin-right: 30px;
    color: #42c655;
    border: 1px solid #42c655;
    background: #fff;
}

.edit-locations-form-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
}

.edit-locations-form-btn-container {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.edit-locations-cancel-btn {
    padding-right: 3px;
}
