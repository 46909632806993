#ss-supplier-bookings-report .ss-select-options-visible {
    width: 100%;
    left: 0;
    max-height: 150px !important;
}

.ss-book-space-form-admin-input {
    margin-top: 2rem;
}

.ss-book-space-form-admin-error {
    color: tomato;
    font-weight: 500;
}