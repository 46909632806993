.ss-location-gallery-container {
    height: 25.71rem;
    overflow: "hidden";
}
@media (max-width: 54.85em) {
    .ss-location-gallery-container{
        display: none !important;
    }
}
.ss-full-width {
    width: 100%;
}
.ss-dflex {
    display: flex;
}

.ss-image-common {
    height: 100
}

.ss-gallery-img {
    object-fit: cover;
    transition: all 0.5s ease-in-out;
}

.ss-gallery-img:hover {
    transform: scale(1.05);
}

.ss-first-multiple-image {
    width: 32.14rem;
}

.ss-flex-1 {
    flex: 1
}

.ss-relative {
    position: relative;
}

.ss-absolute-bottom {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    border-radius: 0.6rem;
    text-transform: uppercase;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 0.9em;
    line-height: 180%;
    text-transform: uppercase;
    color: #3F51B5;
    outline: none;
    transition: all 0.2 ease-in-out;
    user-select: none;
    z-index: 1;
}

.ss-absolute-bottom:hover {
    transform: scale(1.05);
}

.ss-absolute-full {
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    font-family: 'Inter';
    font-weight: 500;
    font-size: 1.1em;
    line-height: 1.9em;
    text-transform: uppercase;
    color: #FFFFFf;
    cursor: pointer;
}

.ss-profile-desc-title {
    font-family: 'Inter';
    font-weight: 500 !important;
    font-size: 1.2em !important;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0.2em !important;
}

.ss-profile-desc {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1em !important;
    line-height: 143%;
    color: black;

}

.ss-flex-col {
    flex-direction: column;
}

.ss-first-image-radius-single {
    border-radius: 0.58rem 0rem 0rem 0.58rem;
}

.ss-overflow-hidden {
    overflow: hidden;
}

.ss-first-image-radius-all {
    border-radius: .58rem;
}

.ss-second-full-radius {
    border-radius: 0rem .58rem .58rem 0rem;
}

.ss-second-first-radius {
    border-radius: 0rem .58rem 0rem 0rem;

}

.ss-second-last-radius {
    border-radius: 0rem 0rem .58rem 0rem;
}

.ss-full-width {
    width: 100%;
}

.ss-full-height {
    height: 100%
}

.ss-half-width {
    width: 50%;
}

.ss-half-height {
    height: 50%;
}

.ss-profile-desc-container {
    margin-top: 1.03em;
    margin-bottom: 0.71em;
}

.ss-gallery-next,
.ss-gallery-prev {
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    box-shadow: .2em 0em .3em rgba(0, 0, 0, 0.14);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;

}

.ss-gallery-next {
    right: 1em;


}

.ss-gallery-prev {
    left: 1em;

}

.ss-btn-disable {
    background-color: #ccc;
    cursor: not-allowed;
}

.ss-gallery-modal-container {
    position: relative;
    overflow: hidden !important;
    height: 100%;
    width: 100%
}

.ss-modal-close-btn {
    position: fixed;
    right: 1rem;
    top: 1rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    z-index: 999;
    box-shadow: .2em 0px 4px.3em rgba(0, 0, 0, .25)
}

.ss-image-container {
    object-fit: cover !important;
}

.ss-booking-space-container {
    padding: 2.85em 2.14em;
    background: var(--gray-background);
    border: 1px solid var(--gray-border);
    box-shadow: 0px 0.28em 1em rgba(0, 0, 0, 0.05);
    border-radius: 0.57em;
}

.ss-tab-btn {
    border: none !important;
    outline: none;
    border-radius: 0.3em !important;
}

.ss-tab-btn span {
    font-size: 1em !important;
    text-transform: capitalize !important;
    font-family: 'Inter' !important;
}

.ss-picker-container {
    border-bottom: .1em solid var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .3em;
}

.ss-date-picker {
    width: 45%;
}

.ss-main-container-profile {
    gap: 1.4em !important;
    cursor: pointer;
    padding: 0px !important;
}

.ss-date-from label {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1.2em !important;
    line-height: 0.85em !important;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.38);
}

.ss-date-from .MuiInputAdornment-root {
    width: 2.2em;
}

.ss-date-to .MuiInputAdornment-root {
    width: 2.2em;
}

.ss-booking-button {
    width: 100% !important;
    margin-top: 1.3em !important;
}

.ss-booking-button span {
    text-transform: uppercase;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1.05em;
    line-height: 1.9em;
    letter-spacing: 0.46px;
}

.MuiSelect-selectMenu {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1em !important;
    line-height: 143%;
}

.ss-title-gap {
    margin-bottom: 0.4em !important;
}

.ss-container-gap {
    row-gap: 1.2em !important;
    column-gap: 1.4em !important;
}

.ss-date-to label {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1.2em !important;
    line-height: 0.85em !important;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.38);
}

.ss-date-to:focus .ss-date-from:focus {
    color: var(--primary-color);
}

.ss-to-title {
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 0.85em !important;
    line-height: 0.85em !important;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.38);
}

.ss-booking-space-container .ss-from-to-date-picker .MuiIconButton-label svg {
    color: rgba(63, 81, 181, 0.5) !important;
}

.ss-booking-space-container .admin-confirm .MuiIconButton-label svg {
    color: #fff !important;
}

.ss-booking-gap-bottom {
    height: 5em !important;
}

.ss-date-picker span svg {
    opacity: 1;
    display: block !important;
    background: url('/app-images/picker.png') no-repeat !important;
    background-size: .9em !important;
    border-width: thin !important;
    padding-bottom: 1em;
}

.ss-location-profile-section-subtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 143%;
    letter-spacing: 0.01rem;
}