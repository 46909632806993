.ss-report-empty {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 50px 0 50px 0;
    color: #5C6771;
    font-size: 12px;
    line-height: 13px;
}
