.ss-forgot-password-section-container{
    height: 26.42em !important;
}
.ss-forgot-password-container{
    box-shadow: 0px 0.2 0.07em -0.14em rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 0.14em 0.14em rgba(0, 0, 0, 0.14)) drop-shadow(0em 0.07em 0.35em rgba(173, 154, 154, 0.12));
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.068) 0%, rgba(63, 81, 181, 0) 100%), url(/app-images/login/login_background.png);
    height: 37.14em;
    background-position: center !important;
    background-size: cover;
}

.ss-forgot-password-content-container{
    background: #FFFFFF;
    box-shadow: 0px .57em .58em -.4em rgba(0, 0, 0, 0.2), 0px 1.1em 1.7em 2px rgba(0, 0, 0, 0.14), 0px .4em 2em .4em rgba(0, 0, 0, 0.12);
    border-radius: 0.6em;
    width: 33em;
    padding: 3.78em 3.78em 1em 3.78em;
    transform: translateY(-50%);
}

.ss-forgot-password-icon-email{
    width: 1.43em;
    height: 2.14em;
    object-fit: contain;
    padding-bottom: 0.6em;
}

.ss-forgot-password-title{
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1.72em !important;
    line-height: 133.4%;
    color: #000000 !important;
    margin-bottom: 2.5em !important;
}

.ss-forgot-password-subtitle{
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1em !important;
    line-height: 143% !important;
    letter-spacing: 0.15px !important;
    color: #000000;
    max-width: 25em;
}

.ss-forgot-password-form{
    margin-top: 1.1em;
}

.ss-forgot-password-form input::placeholder{
    font-family: 'Inter' !important;
    font-weight: 400 !important;
    font-size: 1em !important;
    line-height: 1.9em;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.38);
}
.ss-forgot-password-input{
    margin-bottom: 9em !important;
}
.ss-forgot-password-input label{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.9em !important;
    line-height: 0.9em;
    letter-spacing: 0.15px;
}
.ss-forgot-password-input label{
    color: #6639BF !important;
}

.ss-forgot-password-footer {
    margin-top: 5.14em;
    gap: 0.21em;
}

.ss-forgot-password-footer .ss-forgot-password-account-title, .ss-forgot-password-signup{
    font-family: 'Inter' !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;

}

.ss-forgot-password-footer .ss-forgot-password-signup{
    color: #3F51B5 !important;
}

@media (max-width:550px){
    .ss-forgot-password-content-container {
        width: 100%;
        transform: translateY(-30%);
    }
    .ss-forgot-password-section-container{
        padding: 1.5em;
    }
}