.ss-report-form-base {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background-color: #ffffff;
    text-align: left;
}

.ss-report-form-fieldset {
    text-align: left;
    min-width: 0;
    vertical-align: baseline;
    margin: 0;
    border-radius: 3px;
    padding: 10px 10px 0;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid rgb(221, 221, 221);
    border-left: none;
    border-image: initial;
}
