
.ss-customer-partner-testimonials-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bx-wrapper{
    max-width: 100% !important;
}

.flex_box{
    display: flex;
    gap: 2.2em;
    flex-wrap: wrap;
}

.ss-testimonial-arrow-icon {
    background-color: white;
    border-radius: 50%;
    margin-top: -0.5em;
}

.ss-customer-partner-testimonials-border-top {
    height: 0.5em;
    width: 100vw;
    background-color: #757DE8;
}

.ss-customer-partner-testimonials-head {
    margin-top: 4%;
    margin-bottom: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ss-customer-partner-testimonials-container.ss-tier-swiper-wrapper {
    margin: 4.2rem 0em;
}

.ss-tier-swiper-wrapper .swiper-slide{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ss-tier-swiper-wrapper .swiper-horizontal{
    height: 3.42em;
    margin-right: 2.2em !important;
}

.ss-tier-brand-image {
    max-width: 14.85em;
    height: 100%;
    object-fit: contain;
    user-select: none;
}

.ss-customer-partner-testimonials-item {
    height: 100%;
    width: 31%;
    padding: 1.5em;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.571428em;
}

.ss-customer-partner-testimonials-image {
    height: 2.4em;
    width: fit-content;
    object-fit: contain;
}

.ss-customer-partner-testimonials-content{
    padding-bottom: 7%;
}

.ss-customer-partner-testimonials-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .8em;
}

.ss-customer-partner-testimonials-data-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.ss-first-slider {
    justify-content: flex-start !important;
}
.ss-other-slides {
    justify-content: flex-end !important;
}

@media (width <= 990px){
    .ss-customer-partner-testimonials-item{
       width: 45% !important;
    }
}
@media (width <= 600px){
    .ss-customer-partner-testimonials-item{
       width: 100% !important;
    }
    .ss-tier-swiper-wrapper .swiper-horizontal{
        margin-right: 0em !important;
    }

   .ss-first-slider, .ss-other-slides {
        justify-content: center !important;
    }
}