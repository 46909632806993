.ss-listing-location-list-item {
   border-bottom: 1px solid #E9E9FB;
   padding: 1.1em 0.8em;
}

.ss-listing-card {
   display: flex;
   width: 100%;
   gap: 20px;
}

.ss-listing-title {
   font-size: 1.24em;
   width: 21rem;
   font-family: "Inter";
   font-weight: 500 !important;
   margin-top: .5em !important;
   margin-bottom: .5em !important;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}

.ss-listing-thumnail {
   position: relative;
}

.ss-spacer {
   height: 6.3rem;
}


.ss-profile-details {
   min-height: 100%;
}

.ss-left-side-button,
.ss-right-side-button {
   width: 1.5em;
   height: 1.5em;
   border-radius: 100%;
   border: none;
   outline: none;
   position: absolute;
   top: 45%;
   display: flex;
   justify-content: center;
   align-items: center;
   transform: translateY(-50%);
}

.ss-daily-price {
   margin-right: 1rem !important;
   transform: translateY(30%) !important;
}

.ss-left-side-button {
   left: 0.8em;
   background-color: #a5a4a4;
}

.ss-right-side-button {
   right: 0.8em;
}


.ss-listing-location-item-price {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: .2em !important;
}


@media screen and (max-width: 600px) {
   .ss-listing-location-list-item {
      height: fit-content;
   }

   .ss-listing-title {
      font-size: .7em;
      width: 90%;

   }

   .ss-daily-price {
      font-size: .4em;

   }

}

@media screen and (max-width: 500px) {
   .ss-listing-card {
      flex-direction: column;
      overflow: hidden;

   }

   .ss-listing-thumnail>div {
      width: 100% !important;

   }

   .ss-profile-details {
      padding: .4em;
   }

   .ss-spacer {
      height: fit-content !important;
      margin-bottom: .4em  !important;
     
   }
   .ss-profile-feature-list {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap !important;
      column-gap: 5em;
   }

}