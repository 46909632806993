.ss-close {
    position: absolute;
    top: 21px;
    right: 21px;
    height: 20px;
    width: 20px;
    z-index: 9;
    cursor: pointer;
}

.ss-supplier-detail-close{
    position: absolute;
    right:0;
    border-radius:0 50% 50% 0;
    z-index: 9;
    cursor: pointer;
    height:50px;
    width:50px;
    top:0;
    box-shadow: 5px 1px 4px 0 rgba(0,0,0,0.12);
}
.ss-supplier-detail-close img{
    position: relative;
    top:50%;
    transform: translateY(-50%);
    display: block;
    margin:auto;
    width:30px;
}


@media all and (max-width: 600px) {
    .ss-supplier-detail-close{
        border-radius:50%;
        top:-30px;
        right:35px;
        border:1px solid #e7e7e7;
    }
}