.ss-about-nav-header.header-background {
    position: absolute;
    width: 100%;
    background-image: linear-gradient(180.8deg, rgba(0, 0, 0, 0.7) 23.43%, rgba(0, 0, 0, 0.112) 97.63%), url(/app-images/about/header_background_lg.png);
    background-position: left top;
    height: 650px;
    background-size: cover;
}

@media all and (min-width: 1440px) {
    .ss-about-nav-header.header-background {
        background-image: linear-gradient(180.8deg, rgba(0, 0, 0, 0.7) 23.43%, rgba(0, 0, 0, 0.112) 97.63%), url(/app-images/about/header_background_xl.png);
    }
}

@media all and (min-width: 2880px) {
    .ss-about-nav-header.header-background {
        background-image: linear-gradient(180.8deg, rgba(0, 0, 0, 0.7) 23.43%, rgba(0, 0, 0, 0.112) 97.63%), url(/app-images/about/header_background_xxl.png);
    }
}