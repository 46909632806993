.ss-report-form-label {
    max-width: 100%;
    border: 0;
    vertical-align: baseline;
    line-height: 1.24;
    text-align: left;
    display: block;
    margin: 16px 0 1px 25px;
    padding: 0;
    font-size: 13px;
    font-weight: 500;
    color: #889296;
    margin-left: 0 !important;
}
