.ss-search-results-go-back-parent {
    width: 100%;
    margin-bottom: 2%;
}

.ss-search-results-go-back-container {
    display: flex;
}

.ss-search-results-arrow-go-back {
    margin-right: 0.5em;
}

.ss-search-results-pindrop-go-back {
    color: rgba(0, 0, 0, 0.54);
    margin-right: 0.2em;
    margin-left: 0.2em;
}
