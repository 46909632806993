.ss-recently-booked{
    position: relative;
}
.ss-recently-booked-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2.21rem 1% 0% 1%;
}

.ss-recently-booked-title{
    text-align: center;
    margin-top: 37px !important;
    margin-bottom: 30px !important;
}

.ss-recently-booked {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2%;
    width: 100%;
}

.swiper-horizontal {
    padding-bottom: 1em;
}
.ss-swiper-container{
    display: flex;
    align-items: center;
    position: relative;
}

.ss-recently-booked-title{
    font-size: 32px;
}

.ss-recently-booked-arrow-right, .ss-recently-booked-arrow-left {
    color: #3F51B5;
    border: 0.1em solid white;
    outline: 0.01em solid #3F51B5;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;


}

.ss-carousel-arrow-disabled {
    color: #CBC6C1;
    outline: 0.01em solid #CBC6C1;
}

.ss-recently-booked-arrow:hover {
    transform: scale(1.25);
    color: rgb(23, 37, 49);
}

.ss-recently-booked-arrow.disabled {
   display: none;
}

@media (max-width:550px){
    .ss-recently-booked-arrow-right, .ss-recently-booked-arrow-left {
        display: none !important;
    }
}
