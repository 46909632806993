.ss-report-total-container {
    margin-top: 20px;
    width: 300px;
}

.ss-report-total-label {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    color: rgb(153, 153, 153);
}

.ss-report-total-label-bold {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.ss-report-total-total {
    display: inline-block;
    font-size: 15px;
    padding: 3px;
    font-weight: 700;
}