.footer-copyright {
    margin-bottom: 0;
    color: #5C6771;
    font-size: 12px;
    line-height: 13px;
    margin-top:15px;
}

.ss-footer-img {
    cursor: pointer;
    height: 40px;
}
.ss-footer{
    background-color: #FFFFFF;
}

.ss-footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.42rem 10rem 3.57rem 10rem;
}

.ss-footer-social-icons {
    display: flex;
    justify-content: space-between;
    padding-top: 0.14rem;
}

.ss-footer-li {
    font-size: 13px;
    font-weight: 500;
    color: #324559;
    text-decoration: none;
    outline:0;
}
.search footer{
    display:none;
}
/* footer{
    border-top: 1px solid #BAB7B7;
} */

footer .ss-footer-li a:focus{
    outline:0 !important;
}
footer .ss-footer-li a.active{
    color: var(--primary-color) !important;
    text-decoration: none !important;
    font-weight: 900;
}
.footer-link-text:hover, .ss-footer-li:hover a {
    text-decoration: none !important;
    font-weight: 'bold';
    transition: 'all 0.3s ease-in-out',
}
.footer-page-bottom-message {
    position: relative;
    text-align: center;
    padding: 70px;
    background-image: url(https://static.secur.space/app-images/add_partners_trucks2.jpg);
    background-position: 50%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    display: flex;
}
.footer-page-bottom-message .overlay{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(60,59,59,0.88);
    z-index: 1;
}
.footer-page-bottom-message h3{
    max-width:400px;
    margin:0 auto;
    color: #FFFFFF;		font-size: 18px;	font-weight: 500;	line-height: 23px;	text-align: center;
}
.footer-page-bottom-message a {
    margin: 20px auto;
    border-radius: 17px;
    background-color: #4DAAE1;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    padding:8px 30px;
}
.footer-page-bottom-message .container{
    position: relative;
    z-index: 999;
}

@media all and (min-width: 1000px) {
    #footer-trucks {
        margin-top: 0;
        width: 100%;
        height: 250px;
    }
    .ss-footer-li{
        display: inline;
        margin-left: 15px;
        text-align: left;
    }
}

@media all and (max-width: 999px) {
    #footer-trucks {
        display: none;
    }

    .footer-copyright {
        text-align: center;
    }

    footer a {
        text-align: center;
    }
    .ss-footer-section {
        flex-direction: column;
    }

    .ss-footer-section {
        padding:20px 0;
    }

    .ss-footer-icon-container {
        margin: 0 20px 20px 20px;
        text-align: center;
    }

    .ss-footer-ul {
        display: flex;
        justify-content: center;
        margin: 20px 0;
    }

    .ss-footer-li {
        text-align: center;
        padding:0 15px;
    }
}

@media all and (max-width: 650px) {
    .ss-footer-ul{
        text-align: center;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0px !important;

    }
    .ss-footer-li {
        width:100%;
        margin:10px 0;
        text-align: center;
        padding: 0 8%;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }
}
