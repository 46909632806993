.ss-report-header-title {
    text-align: left;
    padding-top: 0;
    font-family: Montserrat, sans-serif;
    padding-bottom: 13px;
    color: #324559;
    font-size: 32px;
    font-weight: bold;
    line-height: 38px;
}
