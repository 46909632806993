.use-location-restrictions-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding-bottom: 15px;
}

.use-location-restrictions-prompt-text {
    font-weight: bold;
}

.use-location-restrictions-input-container {
    padding-left: 10px;
}

.ss-notification-border{
    border: 1px solid #ddd;
}
.ss-font-bold{
    font-weight: 600;
    display: block;
}