#ssApp {
    position: relative;
    width: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
}

#ssSimplifiedCustomerApp {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

#ssApp > div {
    padding: 0 90px;
    width: 100%;
    float: left;
}

#appNav > div {
    position: relative;
    float: left;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

#appNav + div {
    padding: 0;
    flex:1;
}

.search #appNav + div {
    padding: 20px 0 0;
}

.landing #appNav > div {
    border: 0;
}

.user-based-nav-container {
    display: flex;
    justify-content: space-evenly;
    padding: 0;
}

.user-based-nav-container.sticky {
    position: fixed;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 999;
}

.sticky.ss-margin-none{
    margin-top: -3em;
}

.ss-other-page-nav{
    box-shadow: 0em 0em 0em 0.071em #E0E0E0;
    position: relative;
    z-index: 10;
    background: white;
}

#g-recaptcha > div {
    margin: 0.5em 0em 0em 0em !important; 
}

#navMenuContainer {
    margin-left: 31px;
    min-width: fit-content;
}

#navMenuContainerRight {
    margin: 12px 10px;
    justify-content: flex-end;
}

#navLogoTrans {
    margin: 10px 12px;
    height: 4.3em;
    object-fit: contain;
    width: 15em;
}
.ss-footer-section #navLogoTrans{
    width: 10em;
}
header {
    padding-top: 30px;
    padding-bottom: 30px;
}

.popup-header {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 1.2px solid #EFEBEB;
}

.popup-header img {
    height: 26px;
    padding-right: 11px;
}

.popup-header .close img {
    height: auto;
    padding: 0;
}

.popup-header a img ,
.popup-header span.pointer img {
    padding-right: 0;
}

.popup-header a,
.popup-header span.pointer{
    display: block;
    margin-left: auto;
}

.popup-header h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin-top:0;
}

.orange-button {
    padding: 8px 30px;
    border-radius: 17px;
    background-color: #f79019;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .3px;
    border: 1px solid #f79019;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}
.orange-button:hover{
    color: #f79019;
    background-color: #FFFFFF;
}

.ss-nav-menu-item-highlighted{
    border: 1px solid #f79019;
}

.ss-nav-menu-item-highlighted:hover{
    color: #f79019!important;
    background-color: #FFFFFF;
    border-bottom-width: 1px !important;
}
.ss-action-button {
    width: 100%;
}

.ss-filter-box{
    display: flex;
    align-items: center; 
    margin-top: -0.4em;
}

.ss-filter-box input{
    flex: 1;
}

.app-modal-in > div > div > div,
.app-modal-in > div > div > form {
    padding: 30px 50px;
}

.app-modal {
    background-color: #FFFFFF;
    box-shadow: 0 5px 44px 0 rgba(0, 0, 0, 0.04);
}

.hs-bookings-container {
    width: 100%;
    float: left;
    overflow: hidden;
}

.hs-bookings-container > div {
    padding: 0 90px;
}

.ss-z-index{
    z-index: 1;
}
.ss-margin-top{
    margin-top: 2.8em;
}

.hs-bookings-container > div .breadcrumb {
    padding: 20px 0 7px 0;
    color: #5C6771;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 0;
}

.hs-bookings-container > div .breadcrumb > li + li:before {
    color: #5C6771;
    content: '\2192';
}

.hs-bookings-container > div .content-header-title {
    text-align: left;
    padding-top: 0;
}

.hs-bookings-container > div h2 {
    color: #7C8793;
    font-size: 14px;
    padding: 20px 0;
    font-weight: bold;
    line-height: 18px;
    text-transform: uppercase;
}

button.close > img {
    max-width: 30px;
}

.unselectable {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(47, 47, 47, 0.5);
    z-index: 1000000;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.unselectable .popup-header {
    padding: 30px 50px;
}

.unselectable .popup-header .close {
    margin-left: auto;
    opacity: 1;
    border: none;
    outline: none;
    background-color: transparent;
}

.ss-modal-footer{
    padding: 1em 0em;
}

/* .unselectable form .modal-body {
    padding: 30px 50px;
} */

.container.report-label {
    padding-right: 0;
}

@media all and (min-width: 1000px) {
    #toggleMenuIcon {
        display: none;
    }
    #ss-light_menu_for_landing_page{
        display: none;
    }
}

.app-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    padding: 0;
    min-width: 20rem;
    max-width: 600px;
    max-height: 90%;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 44px 0 rgba(0, 0, 0, 0.04);
}
.ss-main{
    min-height:70vh;
}

.app-modal.modal-page{
height:100%;
}

.app-modal #page-content{
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}


.app-modal #page-content{
    overflow-y: auto !important;
    overflow-x: hidden;
}
.app-modal #page-content .pointer.for-close{
    top:20px;
    right:20px;
    position: fixed;
    background: #ffffff;
    z-index: 999;
    padding: 0;
}
.app-modal.large {
    min-width: 50rem;
}

.app-modal.modal-page {
    min-width: 70%;
}

.app-modal-in {
    transition: opacity 0.5s ease-in;
    opacity: 1
}

.app-modal-out {
    transition: opacity 0.3s ease-out;
    opacity: 0
}

.app-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 1px solid #979797;
    background-color: #383838;
}

.app-backdrop-in {
    transition: opacity 0.3s ease-in;
    opacity: 0.57;
}

.app-backdrop-out {
    transition: opacity 1.5s ease-out;
    opacity: 0;
}

.modal-page .about-header{
margin-top:20px;
}
.modal-page .container{
    max-width:95%;
}
.modal-page footer{
    display: none;
}

.modal-page .for-close {
    padding: 20px;
}
.modal-page .for-close img{
    height: 26px;
    padding-right: 11px;
    float: right;
}


.ss-nav-drawer .MuiPaper-root{
    width: 18em !important;
}

.ss-close-btn{
    border-bottom: 1px solid #ddd;
    padding: 1.5em;
}
.ss-nav-drawer-item{
    padding-left: 1.1em !important;
}

.ss-nav-drawer .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16 {
    overflow-x: auto;
}
.ss-drawer-button span{
    font-weight: 600;
    font-family: 'Inter' !important;
    font-size: 1em !important;
}
.ss-drawer-menu-item-unhighlighted span{
    color: #324559 !important;
}
.ss-drawer-menu-item-highlighted span {
    color: #f79019 !important;
}

.css-zzms1-MuiSnackbar-root{
    z-index: 100000000
}

@media all and (max-width: 999px) {
    #toggleMenuIcon {
        height: 30px;
        width: 30px;
        background-image: url('/app-images/menu_icon_black.svg');
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-color: rgba(0, 0, 0, 0.02);
        cursor: pointer;
    }
    #ss-light_menu_for_landing_page{
        background-image: url('/app-images/menu_icon_light.svg');
        height: 30px;
        width: 30px;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-color: rgba(0, 0, 0, 0.02);
        cursor: pointer;
    }
    .ss-nav-menu #toggleMenuIcon{
        background-image: url('/app-images/menu_icon_light.svg');
    }
    .sticky #ss-light_menu_for_landing_page{
        background-image: url('/app-images/menu_icon_black.svg');
    }
    .sticky.ss-nav-menu #toggleMenuIcon{
        background-image: url('/app-images/menu_icon_black.svg');
    }
    .user-based-nav-container{
        justify-content: space-between;
    }
    #navMenuContainerRight{
        justify-content: flex-start !important;
        width: 20px !important;
    }
    .ss-margin-top{
        margin-top: 5.8em;
    }
    #appNav{
        padding: 0 !important;
    }

    #navMenuContainer {
        margin-right: inherit;
        position:absolute;
        right:5px;
        display: none !important;
    }

    .login-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        background-color: white;
        border: 1px solid #777;
        padding: 2rem;
        opacity: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .reset-password-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: white;
        border: 1px solid #777;
        padding: 2rem;
        opacity: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .sign-up-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: white;
        border: 1px solid #777;
        padding: 2rem;
        opacity: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

}

@media all and (min-width: 600px) and (min-height: 975px) {
    .sign-up-modal {
        border-radius: 5px;
    }

    .login-modal {
        border-radius: 5px;
    }

    .reset-password-modal {
        border-radius: 5px;
    }
}

@media all and (max-width: 600px) {
    #appNav + div {
        padding-left: 20px;
        padding-right: 20px;
    }

    #ssApp > div {
        padding-left: 0;
        padding-right: 0;
    }

    .app-modal {
        max-width: 90%;
    }

    .app-modal-in > div > div > div,
    .app-modal-in > div > div > form {
        padding: 30px 15px;
    }

    .search #appNav + div {
        padding: 20px 5%;
        margin-bottom: 50px;
    }

    .search #appNav {
        border-bottom: 1px solid #e7e7e7;
    }

    .modal-body tr {
        display: flex;
        flex-direction: column;
    }

    .modal-content form .table.text-center .ss-button-primary,
    .modal-content form .table.text-center .ss-button-secondary {
        margin: 5px;
    }

    .sm-overflow {
        overflow: auto;
    }

    .sm-overflow::-webkit-scrollbar {
        display: none;
        height: 10px;
    }

}

.dtable {
    display: table !important;
}

.small-screen {
    display: none;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@media all and (max-width: 999px) {
    .small-screen {
        display: block;
    }
}

.logged-in-as-info {
    width: 300px;
    line-height: 40px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    background-color: #d74747;
}

.logged-in-as-label {
    font-weight: 400 !important;
    margin-right: 15px;
    font-size: 1em;
}

.logged-in-as-info button {
    padding: 0 10px;
    margin: 0 0 0 20px;
    height: 30px;
    line-height: 1;
}

.cameraIcon {
    float: right;
    height: 5%;
    width: 5%;
    margin: 5px 5px;
}

.h-100 {
    height: 100% !important;
}

.w-100 {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}

.float-left {
    float: left !important;
}

.no-border {
    border: none !important;
}

.background-white{
    background: #FFFFFF;
}

.payout-class{
    float: right;
    margin-left: auto
}

.account-font-size {
    font-size: 14px;
}

.dates-fieldset{
    padding: 10px 10px 0 10px;
    border: none;
    border-bottom: solid 1px #dddddd;
    display: flex;
}
.intiate-payout{
    width: 90%
}

.move-right {
    position: absolute;
    top:50%;
    right: 0;
    width: 50px;
    transform: translateY(-50%);
}

.app_btn{
    border: none;
    outline: none;
    background-color: transparent;
}

.csvLink-container {
    margin-top: 15px;
    text-align: right;
}

.ss-title-no-image {
    color: #5D6B7A;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 8px 0;
    background-repeat: no-repeat;
    background-position: left center;
    margin: 24px 0 8px 0;
    width: 100%;
    float: left;
}

.ss-align-self-center {
    align-self: center;
}
