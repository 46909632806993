.ss-booking-payment-parent-container {
    margin: 1rem 2rem 1rem 2rem;
}

.ss-booking-payment-byb-container {
    margin-top: 2rem;
    row-gap: 1.07rem;
}

.ss-byb-card {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    box-shadow: none !important;
}

.ss-byb-card-desc {
    margin-bottom: 2rem;
}

.ss-byb-rental-agreement-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 2em !important;
}



.ss-byb-details-card {
    padding: 2em;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05) !important;
}

.ss-byb-details-child {
    display: flex;
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
}

.ss-byb-details-child label+.css-qzutfu-MuiInputBase-root-MuiInput-root-MuiSelect-root{
    margin-top: 0.6em;
    border-bottom: 0.07rem solid #F7F6F3;
}

.ss-byb-details-text {
    color: var(--text-secondary, rgba(0, 0, 0, 0.60));
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.ss-byb-listing-img {
    border-radius: 0.75em;
    margin-bottom: 1em;
    height: 14em;
    object-fit: cover;
}

.ss-byb-details-city-container {
    display: flex;
    align-items: center;
}

.ss-byb-details-input {
    width: 95%;
}

.ss-byb-details-input.ss-date-picker {
    width: 37%;
}

.ss-byb-details-icon {
    display: flex;
    align-items: center;
    margin-left: 1rem !important;
}

.ss-byb-approval-chip {
    width: 70%;
}

.ss-byb-cost-container {
    display: flex;
    flex-direction: column;
}

.ss-byb-cost-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.ss-byb-content {
    margin-top: 1.5em;
}
